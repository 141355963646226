import { isMobile } from "react-device-detect";
import background from "./assets/img/temporada_2025.mp4";

function Home() {
  if (isMobile) {
    return (
      <div className="container-center-horizontal">
        <video src={background} autoPlay loop controls/>
      </div>
    );
  } else {
    return (
      <div className="container-center-horizontal">
        <video src={background} autoPlay loop controls/>
      </div>
    );
  }
}

export default Home;